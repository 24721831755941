<template>
  <v-container fluid class="ma-0 pa-0 background">
    <v-layout align-center column class="pa-3 min-height-500 mt-5" >
      <h3 class="text-center" :class="{'font-35 py-4 line-height-60 uppercase mt-5': $vuetify.breakpoint.smAndUp, 'font-18 py-3 uppercase text-center': $vuetify.breakpoint.xsOnly}">{{ $ml.get('not_found_title') }}</h3>

      <p class="text-center" :class="{'font-17 py-4': $vuetify.breakpoint.smAndUp, 'font-12 py-3': $vuetify.breakpoint.xsOnly}">{{ $ml.get('not_found_description') }}</p>

      <v-btn outline color="accent" class="px-4 my-3" :class="{'font-17 ': $vuetify.breakpoint.smAndUp, 'font-12': $vuetify.breakpoint.xsOnly}" @click="$goTo('/', 'notfound_goto_home')">{{ $ml.get('general_go_home') }} </v-btn>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'NotFound',
  data () {
    return {
    }
  },
  metaInfo: {
    title: 'Error 404'
  }
}
</script>

<style scoped>
</style>
